.productsHeading {
    margin: 2vmax auto;
    width: 15vw;
    border-bottom: 1px solid rgba(0, 0, 0, 0.171);
    padding: 2vmax;
    color: rgba(0, 0, 0, 0.678);
    font: 500 1.5vmax "Roboto";
    text-align: center;
}

.products {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5vmax;
    justify-content: center;
    min-height: 30vh;
}

.paginationBox {
    display: flex;
    justify-content: center;
    margin: 6vmax;
}
  
.pagination {
    display: flex;
    justify-content: center;
    padding: 0;
}
  
.page-item {
    background-color: rgb(255, 255, 255);
    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    padding: 1vmax 1.5vmax;
    transition: all 0.3s;
    cursor: pointer;
}
.page-item:first-child {
    border-radius: 5px 0 0 5px;
}
  
.page-item:last-child {
    border-radius: 0 5px 5px 0;
}
.page-link {
    text-decoration: none;
    font: 300 0.7vmax "Roboto";
    color: rgb(80, 80, 80);
    transition: all 0.3s;
}
  
.page-item:hover {
    background-color: rgb(230, 230, 230);
}
  
.page-item:hover .page-link {
    color: rgb(0, 0, 0);
}
.pageItemActive {
    background-color: tomato;
}
  
.pageLinkActive {
    color: white;
}

.filterBox {
    width: 8vmax;
    position: absolute;
    top: 15vmax;
    left: 3.5vmax;
}
.categoryBox {
    padding: 0%;
}
  
.category-link {
    list-style: none;
    color: rgba(0, 0, 0, 0.61);
    font: 400 0.8vmax "Roboto";
    margin: 0.4vmax;
    cursor: pointer;
    transition: all 0.5s;
}
.category-link:hover {
    color: tomato;
}

.filterBox > fieldset {
    border: 1px solid rgba(0, 0, 0, 0.329);
}
.filtericon{
    position: absolute;
    display: flex;
    top: 24%;
    left: 2%;
    font: 500 1vmax "Roboto";
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.61);
}
.filtericon > svg{
    color: tomato;
}

@media screen and (max-width: 600px) {
    .filterBox {
      width: 20vmax;
      position: static;
      margin: auto;
    }
    .page-link {
        font: 300 1.7vmax "Roboto";
    }
    .category-link {
        font: 400 1.8vmax "Roboto";
    }
}