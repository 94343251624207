.banner {
    background-image: url("../../images/cover.jfif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vmin;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
}
  
.banner > h1 {
    margin: 5vmax;
  
    font: 600 2.5vmax "Roboto";
}
  
.banner > p {
    font: 300 1.4vmax "Lucida Sans";
}
  
.banner > a > button {
    margin-bottom: 5vmax;
    cursor: pointer;
    background-color: white;
    border: 1px solid white;
    border-radius: 0;
    padding: 1vmax;
    transition: all 0.5s;
    width: 9vmax;
    font: 500 1vmax "Roboto";
}
.banner > a > button:hover {
    background-color: rgba(255, 255, 255, 0);
    color: white;
}
/* .banner::after {
    content: "";
    width: 100vw;
    height: 100vmin;
    background-color: #ffffff;
    position: absolute;
    top: 0%;
    left: 0;
    clip-path: polygon(100% 68%, 0 100%, 100% 100%);
    max-width: 100%;
} */
.homeHeading{
    text-align: center;
    font-family: "Roboto";
    font-size: 1.4vmax;
    border-bottom: 1px solid rgba(21, 21, 21, 0.5);
    width: 20vmax;
    padding: 1vmax;
    margin: 5vmax auto;
    color: rgba(0, 0, 0, 0.7);
}

.container{
    display: flex;
    margin: 2vmax auto;
    width: 80vw;
    flex-wrap: wrap;
    justify-content: center;
}
.productCard > div{
    margin: 0.5vmax;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.productCard{
    width: 15vmax;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: rgb(48,48,48);
    margin: 2vmax;
    transition: all 0.5s;
    padding-bottom: 0.5vmax;
}
.productCard .imageContainer{
    display: flex;
    width: 15vmax;
    height: 20vmax;
    justify-content: center;
    align-items: center;
    margin: 0;
}
.imageContainer > img{
    width: 100%;
}



.productCardSpan{
    margin: 0.5vmax;
    font: 300 0.77vmax "Roboto"
}
.productCard > p{
    font-family: "Roboto";
    font-size: 1.2vmax;
    margin: 1vmax 0.5vmax;
    margin-bottom: 0;
}
.productCard > span {
    margin: 0.5vmax;
    color: tomato;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 1vmax;
}
  
.productCard:hover {
    box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);
  
    transform: translateY(-1vmax);
}

@media screen and (max-width: 600px) {
    .productCard > p {
      font-size: 1.7vmax;
    }
  
    .productCard > div {
      margin: 0vmax;
      display: block;
    }
  
    .productCard > span {
      font-size: 1.5vmax;
    }
  
    .productCard > div > span {
      margin: 0 0.5vmax;
      font: 300 1vmax "Roboto";
    }
}