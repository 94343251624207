.contactContainer {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: wheat;
    position: fixed;
}
.contactContainer::before{
    content: '';
    /* filter: blur(4px); */
    background: url("https://static-cse.canva.com/blob/567353/40examplestohelpyoubuildtheperfectcontactuspage.jpg") no-repeat fixed center;
    background-size: 100% 100%;
    opacity: 0.8;
    position: absolute;
    width: 100%;
    height: 100%;
    /* z-index: 2; */
}
.card{
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 80%;
}
.card::before{
    content: '';
    opacity: 0.8;
    position: absolute;
    z-index: 4;
    background: rgb(255, 255, 255);
    width: 70%;
    height: 80%;
    border-radius: 4px;
}
.card>img{
    width: 17%;
    z-index: 4;
    opacity: 0.5;
    margin-top: 10%;
    margin-bottom: 2%;
    color: white;
}
h1{
    font-family: "Roboto";
    font-size: 4rem;
    z-index: 5;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}
h1>.heading{
    color: tomato;
}
h1>.subHeading{
    font-size: 2rem;
}
.mailBtn {
    text-decoration: none;
    transform: translateY(50vw);
    animation: mailBtnAnimation 2s forwards;
    z-index: 4;
    color: rgb(255, 71, 71);
    margin-bottom: 5%;
}

.mailBtn:hover{
    color: rgb(0, 68, 255);
}

.mailBtn > button {
    text-decoration: none;
    font: 200 2vmax "Roboto";
    cursor: pointer;
    padding: 2vmax;
    text-transform: lowercase;
  }
  
  @keyframes mailBtnAnimation {
    to {
      transform: translateY(0);
    }
  }