.speedDial {
    position: fixed;
    right: 3vmax;
    top: 3vmax;
  }
  
  .speedDialIcon {
    width: 56px;
    height: 56px;
    border-radius: 100%;
  }
.menuBurger{
  top: 1vmax;
  left: 0.5vmax;
}
.menuBurger:hover{
  cursor: url("../../../images/hand1.png"), auto;
}